<template>
  <div class="app-container">

    <div class="table-layout">
      <el-row>
        <el-col :span="6" class="table-cell-title">名称</el-col>
        <el-col :span="6" class="table-cell-title">纳税人识别号</el-col>
        <el-col :span="6" class="table-cell-title">地址</el-col>
        <el-col :span="6" class="table-cell-title">电话</el-col>
      </el-row>
      <el-row>
        <el-col :span="6" class="table-cell">{{invoice.taxName}}</el-col>
        <el-col :span="6" class="table-cell">{{invoice.taxNumber}}</el-col>
        <el-col :span="6" class="table-cell">{{invoice.address}}</el-col>
        <el-col :span="6" class="table-cell">{{invoice.telephone}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="6" class="table-cell-title">开户行</el-col>
        <el-col :span="6" class="table-cell-title">账号</el-col>
        <el-col :span="6" class="table-cell-title">微信</el-col>
        <el-col :span="6" class="table-cell-title">邮箱</el-col>
      </el-row>
      <el-row>
        <el-col :span="6" class="table-cell">{{invoice.accountBank}}</el-col>
        <el-col :span="6" class="table-cell">{{invoice.account}}</el-col>
        <el-col :span="6" class="table-cell">{{invoice.wechatNumber}}</el-col>
        <el-col :span="6" class="table-cell">{{invoice.email}}</el-col>
      </el-row>
    </div>

    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="orderHistoryTable"
                :data="tableData"
                style="width: 100%;"
                v-loading="listLoading" border>
        <el-table-column label="订单编号" align="center">
          <template #default="scope">{{ scope.row.ordersEntity.outTradeNo }}</template>
        </el-table-column>
        <el-table-column label="订单金额" align="center">
          <template #default="scope">{{ scope.row.ordersEntity.expenseMoney }}</template>
        </el-table-column>
        <el-table-column label="订单状态" align="center">
          <template #default="scope">{{ scope.row.ordersEntity.accountStatus == '开通' ? '首次开通' : '续费' }}</template>
        </el-table-column>
        <el-table-column label="支付时间" align="center">
          <template #default="scope">{{ scope.row.ordersEntity.createTime }}</template>
        </el-table-column>
        <el-table-column label="账号周期" align="center">
          <template #default="scope">{{ scope.row.ordersEntity.expenseCycle }}{{ scope.row.ordersEntity.expenseChoice }}</template>
        </el-table-column>
        <el-table-column label="用户" align="center">
          <template #default="scope">{{ scope.row.ordersEntity.username }}</template>
        </el-table-column>
        <el-table-column label="开票状态" align="center">
          <template #default="scope">{{ scope.row.ordersEntity.invoiceStatus == '0' ? '未申请' : '已申请' }}</template>
        </el-table-column>
      </el-table>
    </div>

    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>


<script>


import {detailInvoice, fetchInvoiceOrderList} from '@/api/invoice';


const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  taxId: null,
};

export default {
  name: 'InvoiceDetailList',
  data() {
    return {
      invoice: {},
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total:null,
      listLoading:false
    }
  },
  created() {
    detailInvoice(this.$route.query.tid).then(response => {
      this.invoice = response.data;

      this.listQuery.taxId = this.invoice.id;
      this.getTableList();
    });

  },
  methods: {
    //列表
    getTableList(){
      this.listLoading=true;
      fetchInvoiceOrderList(this.listQuery).then(res=>{
        this.listLoading = false;
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },

  }
}
</script>
<style scoped>

.table-layout {
  margin-top: 20px;
  border-left: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
}

.table-cell {
  height: 60px;
  line-height: 40px;
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
  font-size: 14px;
  color: #606266;
  text-align: center;
  overflow: hidden;
}

.table-cell-title {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
  background: #F2F6FC;
  text-align: center;
  font-size: 14px;
  color: #303133;
}
</style>


