import request from '@/utils/request';

//新增
export function createInvoice(data) {
  return request({
    url :'/invoice/createInvoice',
    method : 'post',
    data : data
  })
}

//列表
export function companyInvoiceList(data) {
  return request({
    url : '/invoice/companyInvoiceList',
    method : 'post',
    data : data
  })
}

//通过订单查询开票信息
export function orderInvoice(data) {
  return request({
    url:'/invoice/orderInvoice',
    method : 'post',
    data : data
  })
}

//查询开票订单列表
export function fetchOrderInvoiceList(data) {
  return request({
    url:'/invoice/orderInvoiceList',
    method : 'post',
    data : data
  })
}

//详情
export function detailInvoice(data) {
  return request({
    url:'/invoice/detailInvoice',
    method : 'post',
    data : data
  })
}


//查询开票订单列表
export function fetchInvoiceOrderList(data) {
  return request({
    url:'/invoice/invoiceOrderList',
    method : 'post',
    data : data
  })
}

//删除
export function deleteInvoice(data) {
  return request({
    url : '/invoice/deleteInvoice',
    method : 'post',
    data : data
  })
}
